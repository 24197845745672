import { OptionValue } from '@orascom/api-interfaces';
import { SelectDropdown } from '@orascom/common-components';
import { changeLanguage } from '@orascom/utils';
import { useState } from 'react';
import styles from './language-dropdown.module.scss';

export function LanguageDropdown() {
  const [lang, setLang] = useState(
    JSON.parse(localStorage.getItem('lang') as string) || {
      label: 'EN',
      value: 'en',
    }
  );

  return (
    <SelectDropdown
      className={styles['wrapper']}
      options={[
        { label: 'EN', value: 'en' },
        { label: 'AR', value: 'ar' },
        { label: 'DE', value: 'de' },
        { label: 'RU', value: 'ru' },
      ]}
      placeholder=""
      selectedOption={lang}
      onChange={(val) => {
        changeLanguage(val as OptionValue);
        setLang(val as OptionValue);
        location.reload();
      }}
      isSearchable={false}
    />
  );
}

export default LanguageDropdown;
