/* eslint-disable import/no-extraneous-dependencies */
import Numeral from 'numeral';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { FilterValue, PaginationPayload } from '@orascom/api-interfaces';
import { Analytics as AnalyticsUtils } from '../../utils/analytics';
import { Accordion, Loader } from '@orascom/common-components';
import { AnalyticsBrokerRankings } from '../../definitions/interfaces/analytics';
import queryString from 'query-string';
import {
  AnalyticsFilter,
  PaginationStyled,
  useAnalyticsFilters,
} from '@orascom/broker-sales-man-common-components';

interface AgentsRankingProps {
  filterDestinations: FilterValue[];
  filterUnitTypes: FilterValue[];
}
export function AgentsRanking(props: Readonly<AgentsRankingProps>) {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);

  const [agentsRanking, setAgentsRanking] =
    useState<PaginationPayload<AnalyticsBrokerRankings[]>>();
  const [loading, setLoading] = useState(true);

  const filtersParams = queryString.parse(window.location.search);

  const perPage = 10;
  const {
    selectedDestinations,
    handleSelectedDestinations,
    selectedDestinationsIds,
    selectedUnitTypes,
    handleSelectedUnitTypes,
    selectedUnitTypesIds,
    startDateArg,
    endDateArg,
  } = useAnalyticsFilters();

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedDestinations, selectedUnitTypes]);

  useEffect(() => {
    AnalyticsUtils.getAnalyticsAgentsRanking({
      page: currentPage.toString(),
      per_page: perPage.toString(),
      from: startDateArg,
      to: endDateArg,
      'destination_ids[]': selectedDestinationsIds,
      'unit_type_ids[]': selectedUnitTypesIds,
    })
      .then((res) => {
        setAgentsRanking(res);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  }, [
    filtersParams['start'],
    filtersParams['end'],
    selectedDestinations,
    selectedUnitTypes,
    currentPage,
  ]);

  if (loading) {
    return <Loader />;
  }
  if (!agentsRanking) {
    return <h1>no rankings found</h1>;
  }
  return (
    <div className="wrapper broker-analytic-wrapper">
      <Accordion
        initialOpenState
        analyticsFilter
        title={
          <h3 className="title">
            {t('agentsRanking')}{' '}
            <AnalyticsFilter
              filterDestinations={props.filterDestinations}
              filterUnitTypes={props.filterUnitTypes}
              handleSelectedDestinations={handleSelectedDestinations}
              handleSelectedUnitTypes={handleSelectedUnitTypes}
            />
          </h3>
        }
      >
        <div className="card ranking-wrapper">
          <div className="analytics-table">
            <table>
              <thead>
                <tr className="broker-tr">
                  <th className="broker-th"> Rank</th>
                  <th className="broker-th">Brokers name</th>
                  <th className="broker-th">Sales</th>
                </tr>
              </thead>
              <tbody>
                {agentsRanking.data?.map((item, index) => (
                  <tr className="broker-tr" key={uuidv4()}>
                    <td className="broker-td">
                      #{perPage * (currentPage - 1) + (index + 1)}
                    </td>
                    <td className="broker-td analytics-table-date">
                      {item.name}
                    </td>
                    <td className="broker-td">
                      {Numeral(item.total_selling_price).format('0,0')}{' '}
                      <span>{item.currency}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <PaginationStyled
              currentPage={currentPage}
              totalCount={agentsRanking.meta.total}
              pageSize={agentsRanking.meta.per_page}
              onPageChange={(page: number) => setCurrentPage(page)}
            />
          </div>
        </div>
      </Accordion>
    </div>
  );
}

export default AgentsRanking;
