import { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../api/routes';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import 'react-phone-input-2/lib/style.css';
import {
  SelectDropdown,
  Loader,
  AsyncSelectDropdown,
} from '@orascom/common-components';
import {
  mapDestinationsResponseToFilterValues,
  mapResponseToFilterValues,
  getDestinationCurrency,
} from '@orascom/utils';
import {
  DESTINATION_SLUG,
  FiltersEnums,
  OptionValue,
  UnitDetails as UnitDetailsInterface,
} from '@orascom/api-interfaces';
import { Unit as UnitUtils } from '../../utils/unit';
import {
  ContactTimeEnum,
  ContactTypeEnum,
  CustomerInquiryEnum,
  NewDealFormInterface,
  NewDealInputInterface,
  PortalPageEnum,
} from '../../definitions/interfaces/inputs/new-deal';
import { Deal as DealUtils } from '../../utils/deal';
import { Filter as FilterUtils } from '../../utils/filter';
import arrowIcon from '../../assets/icons/left-arrow.svg';
import newDealImg from '../../assets/images/new-deal.webp';
import { toast } from 'react-toastify';
import styles from 'libs/broker-sales-man-common-components/src/styles/new-deal.module.scss';
import { SingleValue } from 'react-select';
import { analyticsCreateDealCustomEvent } from '../../utils/analytics-events';
import {
  MultiplePhoneNumbersFields,
  USER_CONTEXT,
} from '@orascom/broker-sales-man-common-components';

export function NewDeal() {
  const { t } = useTranslation();
  const { unitId } = useParams();
  const navigate = useNavigate();

  const [unit, setUnit] = useState<UnitDetailsInterface>();

  const [isLoadingUnit, setIsLoadingUnit] = useState(false);
  const [isLoadingFilters, setIsLoadingFilters] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [destinations, setDestinations] = useState<OptionValue[]>([]);

  const [unitTypes, setUnitTypes] = useState<OptionValue[]>([]);

  const [selectedDestination, setSelectedDestination] =
    useState<OptionValue[]>();
  const [selectedUnitType, setSelectedUnitType] = useState<OptionValue | null>(
    null
  );

  useEffect(() => {
    if (Number(unitId)) {
      setIsLoadingUnit(true);
      UnitUtils.getUnitDetails(Number(unitId))
        .then((res) => setUnit(res))
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setIsLoadingUnit(false));
    }
  }, [unitId]);

  const getUnitsHandler = (
    searchValue: string,
    setUnitOptions?: (options: OptionValue[]) => void
  ) => {
    if (!selectedDestination || selectedDestination?.length === 0) {
      return [];
    }

    const unitsParamsObject: Record<string, string> = {};

    if (selectedDestination.length) {
      const destinationKey = `${FiltersEnums.DESTINATIONS}[0]`;
      unitsParamsObject[destinationKey] = `${selectedDestination[0].value}`;
    }

    if (selectedUnitType) {
      const unitTypeKey = `${FiltersEnums.UNIT_TYPES}[0]`;
      unitsParamsObject[unitTypeKey] = `${selectedUnitType.value}`;
    }

    if (searchValue) {
      unitsParamsObject[FiltersEnums.UNIT_NAME] = searchValue;
    }

    const unitsSearchParams = new URLSearchParams(unitsParamsObject);

    UnitUtils.getUnits(1, 50, unitsSearchParams).then((res) => {
      const unitsNames = res.data.map((unitOption) => {
        return {
          label: unitOption.name,
          value: unitOption.id.toString(),
        };
      });

      if (setUnitOptions) {
        setUnitOptions(unitsNames);
      }
    });
  };

  const getAvailableFilters = () => {
    setIsLoadingFilters(true);

    const filtersParamsObject: Record<string, string> = {};

    if (selectedDestination?.length) {
      const destinationKey = `${FiltersEnums.DESTINATIONS}[0]`;
      const destinationValue = selectedDestination[0].value.toString();

      filtersParamsObject[destinationKey] = destinationValue;
    }

    const filtersSearchParams = new URLSearchParams(filtersParamsObject);

    FilterUtils.getUnitFilters(filtersSearchParams)
      .then((res) => {
        setDestinations(
          mapDestinationsResponseToFilterValues(res.destinations)
        );
        setUnitTypes(mapResponseToFilterValues(res.unit_types));
      })
      .finally(() => setIsLoadingFilters(false));
  };

  useEffect(() => {
    getAvailableFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDestination]);

  const destinationValidation = !unitId && {
    destination: yup.object().nullable().required(t('required')),
  };

  const phoneSchema = yup.object({
    countryCode: yup.string().required(),
    phone: yup.string().required(t('pleaseEnterPhoneNumber')),
  });

  const schema = yup.object({
    firstName: yup.string().required(t('pleaseEnterFirstName')),
    lastName: yup.string().required(t('pleaseEnterLastName')),
    email: yup.string().email(t('pleaseEnterValidEmail')),
    phoneNumbers: yup.array().of(phoneSchema).max(3, 'maxThreePhoneNumbers'),
    ...destinationValidation,
    clientBudget: yup.string().min(1, t('budgetValidation')),
    contactType: yup.object().nullable(),
    contactTime: yup.object().nullable(),
  });

  const userContext = useContext(USER_CONTEXT);
  const location = useLocation();

  const submitHandler = (values: NewDealInputInterface) => {
    const mappedData: NewDealFormInterface = {
      customer_inquiry: CustomerInquiryEnum.UNIT_PURCHASING_INQUIRY,
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phone: values.phoneNumbers[0].phone,
      mobile_country_code: values.phoneNumbers[0].countryCode,
      portal_comments: `${values.additionalInfo} ${values.unitType ?? ''}`,
      // TODO: MISSING CREATE LEAD PAGE
      portal_page: PortalPageEnum.UNIT_PAGE,
      preferred_communication_method: values.contactType?.value
        ? String(values.contactType?.value)
        : '',
      preferred_communication_time: values.contactTime?.value
        ? String(values.contactTime?.value)
        : '',
      destination_slug: unitId
        ? unit?.project.destination.slug ?? ''
        : String(values.destination?.value),
    };

    if (values.phoneNumbers[1]?.countryCode && values.phoneNumbers[1]?.phone) {
      mappedData.phone_2 = `+${values.phoneNumbers[1].countryCode}${values.phoneNumbers[1].phone}`;
    }
    if (values.phoneNumbers[2]?.countryCode && values.phoneNumbers[2]?.phone) {
      mappedData.phone_3 = `+${values.phoneNumbers[2].countryCode}${values.phoneNumbers[2].phone}`;
    }
    if (unitId) {
      mappedData.interested_in_unit_id = unitId;
    }

    if (values.unitName) {
      mappedData.interested_in_unit_id = values.unitName;
    }

    if (values.clientBudget) {
      mappedData.budget = Number(values.clientBudget);
    }

    setIsSubmitting(true);

    DealUtils.createLead(mappedData)
      .then(() => {
        toast.success('Deal Created Successfully');
        navigate(ROUTES['MyDeals'].path);
        analyticsCreateDealCustomEvent({
          userId: userContext.user?.id.toString(),
          timestamp: Date.now().toString(),
          portal: 'Broker',
          pageName: location.pathname,
        });
      })
      .catch((err) => {
        toast.error('failure to add lead');
        console.log(err);
      })
      .finally(() => setIsSubmitting(false));
  };

  const handleDestinationChange = (val: SingleValue<OptionValue>) => {
    if (val) {
      setSelectedDestination([val]);
      setSelectedUnitType(null);
    }
  };
  const handleChangeUnitType = (val: SingleValue<OptionValue>) => {
    setSelectedUnitType(val || null);
  };

  const handleLoadUnitNames = (
    searchValue: string,
    setUnitOptions?: (options: OptionValue[]) => void
  ) => {
    if (!selectedDestination) return;

    getUnitsHandler(searchValue, setUnitOptions);
  };

  if (isLoadingUnit || isSubmitting) {
    return <Loader />;
  }

  const selectedDestinationValue = unitId
    ? unit?.project.destination.slug ?? ''
    : selectedDestination?.[0]?.value;

  const isDestinationNotSelected = !selectedDestination || isLoadingFilters;

  const isClientBudgetFieldDisabled = unitId ? false : isDestinationNotSelected;

  return (
    <div className={styles['wrapper']}>
      <Link to={ROUTES['MyDeals'].path} className={styles['back-anchor']}>
        <img src={arrowIcon} alt="" role="presentation" />
        <span className="anchor">{t('back')}</span>
      </Link>
      <h1 className={styles['title']}>{t('newDeal')}</h1>
      <div className={styles['grid']}>
        <div className={styles['form__wrapper']}>
          <h3>{t('leadInformation')}</h3>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              unitName: '',
              unitType: '',
              clientBudget: '',
              contactType: null,
              contactTime: null,
              destination: null,
              additionalInfo: '',
              phoneNumbers: [{ countryCode: '20', phone: '' }],
            }}
            validationSchema={schema}
            onSubmit={submitHandler}
          >
            {({ errors, setFieldValue, values, touched, isValid }) => (
              <Form className={styles['form']}>
                <div className="form__input">
                  <label htmlFor="firstName">{t('firstName')}</label>
                  <Field
                    id="firstName"
                    name="firstName"
                    type="text"
                    className={
                      errors.firstName && touched.firstName ? 'error' : ''
                    }
                    placeholder={t('prospectiveBuyerFirstName')}
                  />

                  <ErrorMessage
                    name="firstName"
                    component="div"
                    className="form__error"
                  />
                </div>
                <div className="form__input">
                  <label htmlFor="lastName">{t('lastName')}</label>
                  <Field
                    id="lastName"
                    name="lastName"
                    type="text"
                    className={
                      errors.lastName && touched.lastName ? 'error' : ''
                    }
                    placeholder={t('prospectiveBuyerLastName')}
                  />

                  <ErrorMessage
                    name="lastName"
                    component="div"
                    className="form__error"
                  />
                </div>
                <div className="form__input">
                  <label htmlFor="email">
                    {t('email')} ({t('optional')})
                  </label>
                  <Field
                    id="email"
                    name="email"
                    type="email"
                    placeholder="email@example.com"
                  />

                  <ErrorMessage
                    name="email"
                    component="div"
                    className="form__error"
                  />
                </div>

                <MultiplePhoneNumbersFields
                  phoneNumbers={values.phoneNumbers}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />

                {!unitId && (
                  <>
                    <div className="form__input">
                      <label>{t('destination')}</label>
                      <SelectDropdown
                        name="destination"
                        options={destinations}
                        placeholder={t('searchDestination')}
                        className={styles['select']}
                        onChange={(val) => {
                          setFieldValue('unitType', '');
                          setFieldValue('destination', val);
                          handleDestinationChange(val);
                        }}
                        isLoading={isLoadingFilters}
                        disabled={isLoadingFilters}
                        selectedOption={values.destination || null}
                      />

                      <ErrorMessage
                        name="destination"
                        component="div"
                        className="form__error"
                      />
                    </div>

                    <div className="form__input">
                      <label>
                        {t('unitType')} ({t('optional')})
                      </label>
                      <SelectDropdown
                        options={unitTypes}
                        placeholder={t('searchUnitType')}
                        className={styles['select']}
                        disabled={isDestinationNotSelected}
                        isLoading={isLoadingFilters}
                        isClearable
                        onChange={(val) => {
                          setFieldValue('unitType', val?.label);
                          handleChangeUnitType(val);
                        }}
                        selectedOption={selectedUnitType}
                      />

                      <ErrorMessage
                        name="unitType"
                        component="div"
                        className="form__error"
                      />
                    </div>

                    <div className="form__input">
                      <label>
                        {t('unitName')} ({t('optional')})
                      </label>
                      <AsyncSelectDropdown
                        key={
                          JSON.stringify(selectedDestination) +
                          JSON.stringify(selectedUnitType)
                        }
                        placeholder={t('searchUnitName')}
                        className={`${styles['select']} ${
                          errors.unitName && touched.unitName ? 'error' : ''
                        }`}
                        disabled={isDestinationNotSelected}
                        defaultOptions={
                          selectedDestination && selectedDestination?.length > 0
                        }
                        loadOptions={handleLoadUnitNames}
                        onChange={(val) => {
                          setFieldValue('unitName', val?.value);
                        }}
                        isSearchable={false}
                      />

                      <ErrorMessage
                        name="unitName"
                        component="div"
                        className="form__error"
                      />
                    </div>
                  </>
                )}

                <div className="form__input">
                  <label htmlFor="clientBudget">
                    {t('clientBudget')} ({t('optional')})
                  </label>
                  <div className={styles['price-input']}>
                    <Field
                      id="clientBudget"
                      name="clientBudget"
                      type="number"
                      disabled={isClientBudgetFieldDisabled}
                      className={
                        errors.clientBudget && touched.clientBudget
                          ? 'error'
                          : ''
                      }
                      placeholder={t('insertValue')}
                    />
                    <p
                      className={`${styles['price-input__currency']} ${
                        isClientBudgetFieldDisabled && styles['disabled']
                      }`}
                    >
                      {getDestinationCurrency(
                        selectedDestinationValue as DESTINATION_SLUG
                      )}
                    </p>
                  </div>

                  <ErrorMessage
                    name="clientBudget"
                    component="div"
                    className="form__error"
                  />
                </div>
                <div className="form__input">
                  <label htmlFor="contactType">
                    {t('preferredWayOfContact')} ({t('optional')})
                  </label>
                  <SelectDropdown
                    name="contactType"
                    className={`${styles['select']} ${
                      errors.contactType && touched.contactType ? 'error' : ''
                    }`}
                    placeholder={t('selectPlaceholder')}
                    onChange={(option) => setFieldValue('contactType', option)}
                    options={[
                      {
                        value: ContactTypeEnum.VIRTUAL_MEETING,
                        label: 'Virtual Meeting',
                      },
                      { value: ContactTypeEnum.MOBILE, label: 'Mobile' },
                      { value: ContactTypeEnum.EMAIL, label: 'Email' },
                      { value: ContactTypeEnum.WHATS_APP, label: 'WhatsApp' },
                    ]}
                    selectedOption={values.contactType || null}
                  />

                  <ErrorMessage
                    name="contactType"
                    component="div"
                    className="form__error"
                  />
                </div>
                <div className="form__input">
                  <label htmlFor="contactTime">
                    {t('preferredTimeOfContact')} ({t('optional')})
                  </label>
                  <SelectDropdown
                    name="contactTime"
                    className={`${styles['select']} ${
                      errors.contactTime && touched.contactTime ? 'error' : ''
                    }`}
                    placeholder={t('selectPlaceholder')}
                    onChange={(option) => setFieldValue('contactTime', option)}
                    options={[
                      { value: ContactTimeEnum.MORNING, label: 'Morning' },
                      {
                        value: ContactTimeEnum.AFTERNOON,
                        label: 'Afternoon',
                      },
                      { value: ContactTimeEnum.NIGHT, label: 'Night' },
                    ]}
                    selectedOption={values.contactTime || null}
                  />

                  <ErrorMessage
                    name="contactTime"
                    component="div"
                    className="form__error"
                  />
                </div>

                <div className="form__input">
                  <label htmlFor="additionalInfo">{t('additionalInfo')}</label>
                  <Field
                    id="additionalInfo"
                    name="additionalInfo"
                    as="textarea"
                    className={styles['textarea']}
                    placeholder={t('additionalNotes')}
                  />

                  <ErrorMessage
                    name="additionalInfo"
                    component="div"
                    className="form__error"
                  />
                </div>

                <div className={styles['button-wrapper']}>
                  <button
                    className="btn btn--navy"
                    type="submit"
                    disabled={isSubmitting || !isValid}
                  >
                    {t('createDeal')}
                  </button>
                  <Link to={ROUTES['MyDeals'].path} className="anchor">
                    {t('cancel')}
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <img
          src={newDealImg}
          alt="new-deal"
          className={styles['image']}
          loading="lazy"
        />
      </div>
    </div>
  );
}

export default NewDeal;
