import { useState, useEffect, useRef, useContext } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  CountriesEnum,
  CURRENCIES_ENUM,
  DESTINATION_SLUG,
  PaginationPayload,
  Payload,
  UnitDetails as UnitDetailsInterface,
  UnitInterface,
  UnitPaymentTerms,
} from '@orascom/api-interfaces';
import { Loader, MontenegroUnitPaymentPlan } from '@orascom/common-components';
import arrowIcon from '../../assets/icons/left-arrow.svg';
import styles from './unit-details.module.scss';
import { USER_CONTEXT } from '@orascom/broker-sales-man-common-components';
import ImageGallery from '../image-gallery/image-gallery';
import UnitInfo from '../unit-info/unit-info';
import UnitPlan from '../unit-plan/unit-plan';
import UnitPaymentPlan from '../unit-payment-plan/unit-payment-plan';
import UnitSpecs from '../unit-specs/unit-specs';
import SimilarUnits from '../similar-units/similar-units';
import { CommonEventParameters } from '@orascom/utils';
import { CurrencyContext } from '@orascom/utils';

export interface UnitDetailsProps {
  getUnitDetails: (unitId: number) => Promise<UnitDetailsInterface>;
  getUnitPaymentTerms: (unitId: number) => Promise<UnitPaymentTerms[]>;
  primaryUnitsPath: string;
  analyticsBackButtonCustomEvent?: (params: CommonEventParameters) => void;
  portal: 'Broker' | 'Sales Man';
  getSimilarUnits?: (
    unitId: string,
    price: number,
    designType: string
  ) => Promise<PaginationPayload<UnitInterface[]>>;
  similarUnitsGetPath?: Function;
  unitDetailsGetPath?: Function;
  addSavedUnit: (unitId: number) => Promise<Payload<void>>;
  deleteSavedUnit: (unitId: number) => Promise<Payload<void>>;
  downloadOmanSalesOffer?: (unitId: number, unitName: string) => Promise<any>;
  analyticsUnitSalesOfferDownloadCustomEvent?: (
    params: CommonEventParameters
  ) => void;
  analyticsUnitSubmitInterestCustomEvent?: (
    params: CommonEventParameters
  ) => void;
  analyticsUnitBrochureCustomEvent?: (params: CommonEventParameters) => void;
  analyticsUnitSpecsCustomEvent?: (params: CommonEventParameters) => void;
  comparePropertiesPath: string;
  unitDealGetPath?: Function;
}

export function UnitDetails(props: Readonly<UnitDetailsProps>) {
  const [unit, setUnit] = useState<UnitDetailsInterface>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [paymentTermsError, setPaymentTermsError] = useState(false);
  const [paymentTerms, setPaymentTerms] = useState<UnitPaymentTerms[]>([]);
  const unitSpecsRef = useRef<null | HTMLDivElement>(null);

  const userContext = useContext(USER_CONTEXT);
  const location = useLocation();
  const [selectedPaymentTerms, setSelectedPaymentTerms] =
    useState<UnitPaymentTerms>();
  const handleSelectPaymentTerms = (paymentTerm: UnitPaymentTerms) => {
    setSelectedPaymentTerms(paymentTerm);
  };
  const {
    isLoading: isLoadingCurrency,
    setDisableCurrencySetting,
    setActiveCurrency,
  } = useContext(CurrencyContext);

  const { t } = useTranslation();

  const { unitId } = useParams();

  useEffect(() => {
    if (isNaN(Number(unitId))) {
      return;
    }
    props
      .getUnitDetails(Number(unitId))
      .then((res) => {
        setUnit(res);
        if (
          props.portal === 'Broker' &&
          res.project.destination.slug === DESTINATION_SLUG.GOUNA
        ) {
          setActiveCurrency(CURRENCIES_ENUM.USD);
          setDisableCurrencySetting({
            disabled: true,
            info: t(`elGounaCurrencyDisclaimer`),
          });
        }
      })
      .catch(() => setError(true))
      .finally();

    props
      .getUnitPaymentTerms(Number(unitId))
      .then((terms) => {
        setPaymentTerms(terms);
      })
      .catch(() => {
        setPaymentTermsError(true);
      })
      .finally(() => setLoading(false));

    return () =>
      setDisableCurrencySetting({
        disabled: false,
        info: '',
      });
  }, [unitId]);

  const navigateToUnitSpecs = () => {
    unitSpecsRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const unitCountry = unit?.project.destination.country.slug;
  if (error) {
    return <h1 className="empty-unit__placholder">{t('noUnitFound')}</h1>;
  }
  if (loading || (props.portal === 'Broker' && isLoadingCurrency)) {
    return <Loader />;
  }
  const unitGallery: string[] =
    unit?.gallery && unit?.gallery?.length > 0
      ? unit?.gallery
      : ([
          unit?.cover_image,
          unit?.hover_image,
          unit?.area_image,
          unit?.cover_image,
          unit?.hover_image,
        ] as string[]);

  const montenegroPaymentPlan = paymentTermsError ? (
    <div id="payment-plan" className={`${styles['wrapper']} card`}>
      <h3>No payment plan found for this unit</h3>
    </div>
  ) : (
    <MontenegroUnitPaymentPlan
      unitDetails={unit}
      paymentTerms={paymentTerms}
      handleSelectPaymentTerms={handleSelectPaymentTerms}
      selectPaymentTerms={selectedPaymentTerms}
    />
  );

  return (
    <div className={styles['wrapper']}>
      <Link
        to={props.primaryUnitsPath}
        className={styles['back-anchor']}
        onClick={() =>
          props.analyticsBackButtonCustomEvent?.({
            userId: userContext.user?.id.toString(),
            timestamp: Date.now().toString(),
            portal: props.portal,
            pageName: location.pathname,
          })
        }
      >
        <img src={arrowIcon} alt="" role="presentation" />
        <span className="anchor">{t('backToAllUnits')}</span>
      </Link>
      {unit && (
        <>
          <div className={styles['details']}>
            <ImageGallery images={unitGallery} />
            <UnitInfo
              unit={unit}
              navigateToUnitSpecs={navigateToUnitSpecs}
              addSavedUnit={props.addSavedUnit}
              deleteSavedUnit={props.deleteSavedUnit}
              analyticsUnitSubmitInterestCustomEvent={
                props.analyticsUnitSubmitInterestCustomEvent
              }
              analyticsUnitBrochureCustomEvent={
                props.analyticsUnitBrochureCustomEvent
              }
              analyticsUnitSpecsCustomEvent={
                props.analyticsUnitSpecsCustomEvent
              }
              comparePropertiesPath={props.comparePropertiesPath}
              unitDealGetPath={props.unitDealGetPath}
              portal={props.portal}
            />
          </div>

          {unit.masterplans?.length > 0 && (
            <UnitPlan title={t('floorPlan')} plans={unit.masterplans} />
          )}

          {unitCountry === CountriesEnum.MONTENEGRO ? (
            montenegroPaymentPlan
          ) : (
            <UnitPaymentPlan
              getUnitDetails={props.getUnitDetails}
              getUnitPaymentTerms={props.getUnitPaymentTerms}
              analyticsUnitSalesOfferDownloadCustomEvent={
                props.analyticsUnitSalesOfferDownloadCustomEvent
              }
              downloadOmanSalesOffer={props.downloadOmanSalesOffer}
              portal={props.portal}
            />
          )}
          <div className={`card ${styles['masterplan']}`}>
            <h3>{t('masterPlan')}</h3>
            <div className={styles['image']}>
              <img
                src={unit.masterplan_image}
                alt="masterplan"
                loading="lazy"
              />
            </div>
          </div>

          <UnitSpecs unit={unit} ref={unitSpecsRef} />
          {props.getSimilarUnits && (
            <SimilarUnits
              price={unit.dollar_price}
              unitId={unit.id}
              designType={unit.design_type}
              getSimilarUnits={props.getSimilarUnits}
              similarUnitsGetPath={props.similarUnitsGetPath}
              unitDetailsGetPath={props.unitDetailsGetPath}
              addSavedUnit={props.addSavedUnit}
              deleteSavedUnit={props.deleteSavedUnit}
            />
          )}
        </>
      )}
    </div>
  );
}

export default UnitDetails;
