import { Link } from 'react-router-dom';
import Numeral from 'numeral';
import { Payload, UnitInterface } from '@orascom/api-interfaces';
import areaIcon from '../../assets/icons/area.svg';
import bedroomIcon from '../../assets/icons/bedroom.svg';
import bathroomIcon from '../../assets/icons/bathroom.svg';
import './property-card.scss';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import saveIcon from '../../assets/icons/save.svg';
import savedIcon from '../../assets/icons/saved.svg';
import { useSaveUnit } from '../../utils/save-unit';
import { useCompareUnitsContext } from '@orascom/broker-sales-man-common-components';
import { useCurrencyConverter } from '@orascom/utils';

export interface PropertyCardProps {
  portal: 'broker' | 'sales-man';
  unit: UnitInterface;
  handleRemove: ((id: number) => void) | undefined;
  listingView?: 'list' | 'grid';
  responsiveImages?: boolean;
  unitDetailsPath?: Function;
  addSavedUnit: (unitId: number) => Promise<Payload<void>>;
  deleteSavedUnit: (unitId: number) => Promise<Payload<void>>;
}

export function PropertyCard(props: Readonly<PropertyCardProps>) {
  const { t } = useTranslation();
  const { unit } = props;

  // get price in selected currency
  const { convertToSelectedCurrency, selectedCurrency } =
    useCurrencyConverter();
  const { price } = convertToSelectedCurrency(unit.price, unit.currency);

  const { saveUnit, isSaved, isSaving, setIsSaved } = useSaveUnit(
    unit,
    props.addSavedUnit,
    props.deleteSavedUnit
  );
  const { compareUnitsIds, addUnitToCompare, removeUnitFromCompare } =
    useCompareUnitsContext();

  const isInComparison = compareUnitsIds?.includes(unit.id);

  useEffect(() => {
    setIsSaved(unit.is_user_saved);
  }, [unit]);

  const handleAction = () => {
    if (props.handleRemove) {
      props.handleRemove(unit.id);
    } else {
      saveUnit();
    }
  };

  return (
    <div className="property-card">
      {props.portal === 'broker' && (
        <button
          className="property-card__save-unit"
          onClick={handleAction}
          onKeyDown={handleAction}
          disabled={isSaving}
        >
          <img
            src={isSaved ? savedIcon : saveIcon}
            alt=""
            role="presentation"
          />
        </button>
      )}
      <Link
        to={props.unitDetailsPath ? props.unitDetailsPath(unit.id) : '#'}
        className={` ${
          props.listingView &&
          props.listingView === 'list' &&
          'property-card--list-view'
        }`}
      >
        <div className="property-card__image-wrapper">
          {Boolean(unit.is_resale) && (
            <span className="property-card__tag">{t('resale')}</span>
          )}
          <img
            src={unit.cover_image}
            alt={unit.unit_type.name}
            className={`property-card__image ${
              props.responsiveImages ? 'property-card__image-responsive' : ''
            }`}
            loading="lazy"
          />
          <div className="property-card__overlay">{t('viewUnit')}</div>
        </div>
        <div className="property-card__content">
          <h5>
            {unit.project.name} - {unit.project.destination.name} | {unit.name}
          </h5>
          <h3>{unit.unit_type.name}</h3>

          <h2 className="property-card__content--price">
            {Numeral(price).format('0,0')} {selectedCurrency}
          </h2>
          <div className="property-card__details">
            {props.listingView === 'list' && (
              <h2>
                {Numeral(price).format('0,0')} {selectedCurrency}
              </h2>
            )}
            {unit.built_up_area ? (
              <div>
                <img src={areaIcon} alt="" role="presentation" />
                {unit.built_up_area} {t('mSquared')}
              </div>
            ) : null}
            {unit.bedrooms ? (
              <div>
                <img src={bedroomIcon} alt="" role="presentation" />
                {unit.bedrooms} {t('bedrooms')}
              </div>
            ) : null}
            {unit.bathrooms ? (
              <div>
                <img src={bathroomIcon} alt="" role="presentation" />
                {unit.bathrooms} {t('bathrooms')}
              </div>
            ) : null}
          </div>
          <button
            className="property-card__compare-unit"
            onClick={(e) => e.stopPropagation()}
          >
            <input
              type="checkbox"
              id={`add-unit-${unit.id}`}
              name="add-unit"
              checked={isInComparison}
              onChange={() => {
                if (isInComparison) removeUnitFromCompare(unit.id);
                else addUnitToCompare(unit.id);
              }}
            />
            <label htmlFor={`add-unit-${unit.id}`}>
              {isInComparison ? t('addedToComparison') : t('addToCompare')}
            </label>
          </button>
        </div>
        <div className="property-card__details--list-responsive">
          {unit.built_up_area ? (
            <div>
              <img src={areaIcon} alt="" role="presentation" />
              {unit.built_up_area} {t('mSquared')}
            </div>
          ) : null}
          {unit.bedrooms ? (
            <div>
              <img src={bedroomIcon} alt="" role="presentation" />
              {unit.bedrooms} {t('bedrooms')}
            </div>
          ) : null}
          {unit.bathrooms ? (
            <div>
              <img src={bathroomIcon} alt="" role="presentation" />
              {unit.bathrooms} {t('bathrooms')}
            </div>
          ) : null}
        </div>
        {props.listingView === 'list' && (
          <div className="property-card__floor-plan">
            {unit.masterplan_image ? (
              <img
                src={unit.masterplan_image}
                alt={unit.unit_type.name}
                loading="lazy"
              />
            ) : null}
          </div>
        )}
      </Link>
    </div>
  );
}

export default PropertyCard;
