import styles from './progress-bar.module.scss';

export interface ProgressBarProps {
  completedPercentage: number;
  completedNumber: string | number;
  currency?: string;
  showPercentageInBar?: boolean;
}

export function ProgressBar(props: Readonly<ProgressBarProps>) {
  const {
    completedPercentage,
    completedNumber,
    currency,
    showPercentageInBar,
  } = props;

  const hideBarContainer =
    (completedPercentage > 20 && Number(completedNumber) > 1000) ||
    !showPercentageInBar;

  const hiddenContainer = (
    <div
      className={styles['progress-bar__filler']}
      style={{ width: `${completedPercentage}%`, minWidth: 'unset' }}
    >
      <span className={styles['progress-bar__label']}>
        {completedNumber.toLocaleString()} {currency}{' '}
        {showPercentageInBar && `(${+completedPercentage.toFixed(1)}%)`}
      </span>{' '}
    </div>
  );

  const displayedContainer = (
    <div className={styles['progress-bar__filler-container']}>
      <div
        className={styles['progress-bar__filler']}
        style={{ width: `${completedPercentage}%`, minWidth: 'unset' }}
      />
      <span className={styles['progress-bar__label']}>
        {completedNumber.toLocaleString()} {currency}{' '}
        {showPercentageInBar && `(${+completedPercentage.toFixed(1)}%)`}
      </span>
    </div>
  );

  const barContainer = hideBarContainer ? hiddenContainer : displayedContainer;

  return (
    <div className={styles['progress-bar']}>
      {Number(completedNumber) > 0 ? barContainer : null}
    </div>
  );
}

export default ProgressBar;
