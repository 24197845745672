import { useTranslation } from 'react-i18next';
import styles from './commisions-insights.module.scss';
import unitsRevenue from '../../assets/icons/units-revenue.svg';
import totalCommissions from '../../assets/icons/total-commissions.svg';
import pendingCommissions from '../../assets/icons/pending-commissions.svg';
import avgCommissionTime from '../../assets/icons/commission-time.svg';
import topCommissionTime from '../../assets/icons/top-commission-time.svg';
import leastCommissionTime from '../../assets/icons/least-commission-time.svg';
import BrokerRankings from '../broker-rankings/broker-rankings';
import { useContext, useEffect, useState } from 'react';
import {
  AnalyticsCommissions,
  AnalyticsCommissionsRankingResponse,
} from '../../definitions/interfaces/analytics';
import { Analytics as AnalyticsUtils } from '../../utils/analytics';
import { FilterValue, UserRole } from '@orascom/api-interfaces';
import { Accordion, Loader } from '@orascom/common-components';
import queryString from 'query-string';
import CommissionsBreakdown from '../commissions-breakdown/commissions-breakdown';
import { AnalyticsSalesManager as AnalyticsSalesManagerUtils } from '../../utils/analytics-sales-manager';
import {
  AnalyticsFilter,
  useAnalyticsFilters,
  USER_CONTEXT,
} from '@orascom/broker-sales-man-common-components';

interface CommisionsInsightsProps {
  filterDestinations: FilterValue[];
  filterUnitTypes: FilterValue[];
  filterBrokerAgents: FilterValue[];
  downloadingReport?: boolean;
}
export function CommisionsInsights(props: Readonly<CommisionsInsightsProps>) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const userContext = useContext(USER_CONTEXT);
  const filtersParams = queryString.parse(window.location.search);

  const [comissions, setComissions] = useState<AnalyticsCommissions>();

  const {
    selectedDestinations,
    handleSelectedDestinations,
    selectedDestinationsIds,
    selectedUnitTypes,
    handleSelectedUnitTypes,
    selectedBrokerAgents,
    handleSelectedBrokerAgents,
    selectedBrokerage,
    handleSelectedBrokerage,
    selectedUnitTypesIds,
    selectedBrokerAgentsIds,
    selectedBrokeragesIds,
    startDateArg,
    endDateArg,
  } = useAnalyticsFilters();

  const [comissionsRanking, setComissionsRanking] =
    useState<AnalyticsCommissionsRankingResponse>();

  useEffect(() => {
    if (userContext.user?.role === UserRole.SALES_MANAGER) {
      AnalyticsSalesManagerUtils.getAnalyticsCommissionsDetailsSalesManager({
        from: startDateArg,
        to: endDateArg,
        'destination_ids[]': selectedDestinationsIds,
        'unit_type_ids[]': selectedUnitTypesIds,
        'brokerage_company_ids[]': selectedBrokeragesIds,
      })
        .then((res) => {
          setComissions(res);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoading(false);
        });
    } else {
      Promise.all([
        AnalyticsUtils.getAnalyticsCommissions({
          from: startDateArg,
          to: endDateArg,
          'destination_ids[]': selectedDestinationsIds,
          'unit_type_ids[]': selectedUnitTypesIds,
          'broker_ids[]': selectedBrokerAgentsIds,
        }),
        AnalyticsUtils.getAnalyticsCommissionsRanking({
          from: startDateArg,
          to: endDateArg,
        }),
      ])
        .then((res) => {
          setComissions(res[0]);
          setComissionsRanking(res[1]);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoading(false);
        });
    }
  }, [
    filtersParams['start'],
    filtersParams['end'],
    selectedDestinations,
    selectedUnitTypes,
    selectedBrokerAgents,
    selectedBrokerage,
  ]);

  const totalValueCashed = comissions?.cashed.total_value ?? 0;
  const totalValueLatePending = comissions?.pending.late_deals ?? 0;
  const totalValueOnTimePending = comissions?.pending.late_deals
    ? comissions?.pending.deals - comissions?.pending.late_deals
    : 0;
  const totalValuePending = comissions?.pending.total_value ?? 0;
  const totalDealsPending = comissions?.pending.deals ?? 0;
  const totalSum = totalValueCashed + totalValuePending;

  const commissionsBreakdown = [
    {
      title: t('pendingCommissions'),
      value: totalValuePending,
      color: '#003A70',
      percentage:
        totalSum > 0 ? +((totalValuePending / totalSum) * 100).toFixed(2) : 0,
    },
    {
      title: t('cashedCommissions'),
      value: totalValueCashed,
      color: '#B4905C',
      percentage:
        totalSum > 0 ? +((totalValueCashed / totalSum) * 100).toFixed(2) : 0,
    },
  ];

  const commissionsPendingStatus = [
    {
      title: t('on Time Pending Commissions'),
      value: totalValueOnTimePending,
      color: '#003A70',
      percentage:
        totalDealsPending > 0
          ? +((totalValueOnTimePending / totalDealsPending) * 100).toFixed(2)
          : 0,
    },
    {
      title: t('Late Commissions'),
      value: totalValueLatePending,
      color: '#B4905C',
      percentage:
        totalDealsPending > 0
          ? +((totalValueLatePending / totalDealsPending) * 100).toFixed(2)
          : 0,
    },
  ];
  const comissionsCardContent = (
    <div className={`${styles['comissions-card']} card`}>
      {userContext.user?.role === UserRole.SALES_MANAGER && (
        <div className={styles['card-filters']}>
          <AnalyticsFilter
            buyerCountry
            unitSizeFilter
            filterDestinations={props.filterDestinations}
            filterUnitTypes={props.filterUnitTypes}
            filterBrokerages={props.filterBrokerAgents}
            handleSelectBrokerages={handleSelectedBrokerage}
            handleSelectedDestinations={handleSelectedDestinations}
            handleSelectedUnitTypes={handleSelectedUnitTypes}
          />
        </div>
      )}

      <div
        className={`${styles['commisions-insights-wrapper']} broker-cards-grid`}
      >
        <div className="overview-card">
          <div className="icon">
            <img src={totalCommissions} alt="" role="presentation" />
          </div>
          <div className="content">
            <p>{t('totalCommissions')}</p>
            <h2>
              {totalSum.toLocaleString()} {comissions?.currency}
            </h2>
          </div>
        </div>
        <div className="overview-card">
          <div className="icon">
            <img src={unitsRevenue} alt="" role="presentation" />
          </div>
          <div className="content">
            <p>{t('cashedCommissions')}</p>
            <h2>
              {totalValueCashed.toLocaleString()} {comissions?.currency}{' '}
              <span>{comissions?.cashed.deals} deals</span>
            </h2>
          </div>
        </div>
        <div className="overview-card">
          <div className="icon">
            <img src={pendingCommissions} alt="" role="presentation" />
          </div>
          <div className="content">
            <p>{t('pendingCommissions')}</p>
            <h2>
              {totalValuePending.toLocaleString()} {comissions?.currency}{' '}
              <span>
                {comissions?.pending.deals} {t('deals')}
              </span>
            </h2>
          </div>
        </div>
        {userContext.user?.role === UserRole.SALES_MANAGER && (
          <>
            <div className="overview-card">
              <div className="icon">
                <img src={avgCommissionTime} alt="" role="presentation" />
              </div>
              <div className="content">
                <p>{t('avgCommissionTime')}</p>
                <h2>
                  {comissions?.time?.avg_commission_time} {t('businessDays')}
                </h2>
              </div>
            </div>
            <div className="overview-card">
              <div className="icon">
                <img src={topCommissionTime} alt="" role="presentation" />
              </div>
              <div className="content">
                <p>{t('topCommissionTime')}</p>
                <h2>
                  {comissions?.time?.top_commission_time} {t('businessDays')}
                </h2>
              </div>
            </div>
            <div className="overview-card">
              <div className="icon">
                <img src={leastCommissionTime} alt="" role="presentation" />
              </div>
              <div className="content">
                <p>{t('leastCommissionTime')}</p>
                <h2>
                  {comissions?.time?.least_commission_time} {t('businessDays')}
                </h2>
              </div>
            </div>
          </>
        )}
      </div>
      {userContext.user?.role === UserRole.SALES_MANAGER ? (
        <CommissionsBreakdown
          commissionsBreakdownData={commissionsBreakdown}
          commissionsPendingStatusData={commissionsPendingStatus}
          totalCommissions={totalSum}
          totalPendingCommissions={totalDealsPending}
          currency={comissions?.currency ?? t('egp')}
          downloadingReport={props.downloadingReport}
        />
      ) : null}
    </div>
  );

  if (loading) {
    return <Loader />;
  }
  if (userContext.user?.role === UserRole.SALES_MANAGER) {
    return (
      <div className={`${styles['wrapper']} broker-analytic-wrapper`}>
        <h3 className="title">{t('commisionsInsights')} </h3>
        {comissionsCardContent}
      </div>
    );
  } else {
    return (
      <div className={`${styles['wrapper']} broker-analytic-wrapper`}>
        <Accordion
          analyticsFilter
          initialOpenState
          title={
            <h3 className="title">
              {t('commisionsInsights')}{' '}
              <AnalyticsFilter
                filterDestinations={props.filterDestinations}
                filterUnitTypes={props.filterUnitTypes}
                filterBrokerAgents={props.filterBrokerAgents}
                handleSelectBrokerAgents={handleSelectedBrokerAgents}
                handleSelectedDestinations={handleSelectedDestinations}
                handleSelectedUnitTypes={handleSelectedUnitTypes}
              />
            </h3>
          }
        >
          {comissionsCardContent}
        </Accordion>
        {userContext.user?.role === UserRole.BROKER ? (
          <BrokerRankings
            comissionsRanking={comissionsRanking?.data}
            currency={comissionsRanking?.currency}
          />
        ) : null}
      </div>
    );
  }
}

export default CommisionsInsights;
