import { useContext, useEffect, useMemo, useState } from 'react';
import {
  DealInterface,
  DealStatusEnum,
  SaleStatusEnum,
  SalesInvoiceInterface,
} from '../../definitions/interfaces/deals.interface';
import { Link, useLocation, useParams } from 'react-router-dom';
import Numeral from 'numeral';
import { format } from 'date-fns';
import { ROUTES } from '../../api/routes';
import arrowIcon from '../../assets/icons/left-arrow.svg';
import { useTranslation } from 'react-i18next';
import greenBulletIcon from '../../assets/icons/green-bullet.svg';
import orangeBulletIcon from '../../assets/icons/orange-bullet.svg';
import DealDetailsUnitCard from '../../components/common/deal-details-unit-card/deal-details-unit-card';
import DealDetailsBreadcrumbs from '../../components/deal-details-breadcrumbs/deal-details-breadcrumbs';
import { Deal as DealUtils } from '../../utils/deal';
import styles from './deal-details.module.scss';
import { MeetingInterface } from '../../definitions/interfaces/inputs/new-deal';
import { Loader, SelectDropdown } from '@orascom/common-components';
import LeadScheduledMeetings from '../../components/lead-scheduled-meetings/lead-scheduled-meetings';
import LeadInfoCard from '../../components/lead-info-card/lead-info-card';
import DealDetailsInvoice from '../../components/deal-details-invoice/deal-details-invoice';
import SubmitInvoice from '../../components/submit-invoice/submit-invoice';
import { USER_CONTEXT } from '@orascom/broker-sales-man-common-components';
import { DESTINATION_SLUG, OptionValue } from '@orascom/api-interfaces';

export function DealDetails() {
  const { t } = useTranslation();

  const location = useLocation();
  const salesId = location.state?.salesId;

  const [leadInfo, setLeadInfo] = useState<DealInterface>();
  const [loading, setLoading] = useState<boolean>(false);
  const [submittingInvoice, setSubmittingInvoice] = useState<boolean>(false);
  const { dealId, countryId, duplicate, brokerId } = useParams();
  const [leadMeetings, setLeadMeetings] = useState<MeetingInterface[]>([]);
  const [salesInvoices, setSalesInvoices] = useState<SalesInvoiceInterface[]>(
    []
  );
  const [selectedSaleOption, setSelectedSaleOption] = useState<OptionValue>();

  const [selectedSaleId, setSelectedSaleId] = useState<string | undefined>(
    undefined
  );

  const selectedSale = useMemo(
    () => leadInfo?.sales?.find((sale) => sale.source_id === selectedSaleId),
    [selectedSaleId, leadInfo]
  );
  const userContext = useContext(USER_CONTEXT);

  useEffect(() => {
    if (dealId && countryId && brokerId) {
      const promises = [];
      setLoading(true);
      setSubmittingInvoice(false);
      promises.push(
        DealUtils.getLeadById(
          dealId,
          countryId,
          duplicate === '1' ? '1' : '0',
          brokerId
        )
          .then((res) => {
            setLeadInfo({
              ...res.data,
              ...res.data?.lead,
            });
            if (res.data.sales?.length && !selectedSale) {
              setSelectedSaleOption({
                value: res.data?.sales?.[0].source_id,
                label: res.data?.sales?.[0].unit.name,
              });

              setSelectedSaleId(res.data?.sales?.[0].source_id);
            }

            setLoading(false);
          })
          .catch((err) => console.log(err))
      );
    }
  }, [dealId, submittingInvoice]);

  useEffect(() => {
    if (userContext?.user?.abilities?.invoice?.submit && selectedSale) {
      setSalesInvoices(selectedSale.sales_invoices ?? []);
    }
  }, [dealId, selectedSaleId]);

  const salesOptions =
    leadInfo?.sales &&
    leadInfo?.sales?.length > 1 &&
    leadInfo?.sales.map((sale) => ({
      label: sale.unit?.name,
      value: sale.source_id,
    }));

  useEffect(() => {
    if (salesId && salesOptions) {
      const matchingOption = salesOptions.find(
        (option) => option.value.toString() === salesId
      );

      if (matchingOption) {
        setSelectedSaleOption(matchingOption);
        setSelectedSaleId(matchingOption.value.toString());
      }
    }
  }, [salesId, salesOptions]);

  if (loading) {
    return <Loader />;
  }

  const dealStatus =
    leadInfo &&
    (leadInfo.sales?.length ? selectedSale?.deal_status : leadInfo.lead_status);

  const reservedSaleStatus =
    leadInfo &&
    (leadInfo.sales?.length ? selectedSale?.reserved_sale_status : '');

  const dealUnit =
    leadInfo?.sales?.length && selectedSale?.unit
      ? selectedSale?.unit
      : leadInfo?.interested_in_unit_details;

  const makadiOrOwestDeal =
    selectedSale?.unit?.project.destination.slug === DESTINATION_SLUG.OWEST ||
    selectedSale?.unit?.project.destination.slug ===
      DESTINATION_SLUG.MAKADI_HEIGHTS;

  const submitInvoice =
    dealStatus === DealStatusEnum.DEAL_CLOSED ||
    (dealStatus === DealStatusEnum.LEAD_RESERVED &&
      selectedSale?.contractual_date &&
      makadiOrOwestDeal) ||
    ((dealStatus === DealStatusEnum.SUBMITTED_INVOICE ||
      dealStatus === DealStatusEnum.INVOICE_APPROVED) &&
      makadiOrOwestDeal);

  return (
    <div className={styles['wrapper']}>
      <Link to={ROUTES['MyDeals'].path} className={styles['back-anchor']}>
        <img src={arrowIcon} alt="" role="presentation" />
        <span className="anchor">{t('back')}</span>
      </Link>
      <h1 className={styles['title']}>{t('dealDetails')}</h1>
      <div className="card">
        {salesOptions ? (
          <div className={styles['sales']}>
            <label htmlFor="status">Select Unit to View Info</label>
            <SelectDropdown
              className={styles['sales-select']}
              placeholder={salesOptions[0].label}
              onChange={(option) => {
                if (option) {
                  setSelectedSaleOption(option);
                  setSelectedSaleId(option.value.toString());
                }
              }}
              defaultValue={selectedSaleOption}
              selectedOption={selectedSaleOption}
              options={salesOptions}
            />
          </div>
        ) : null}

        <DealDetailsBreadcrumbs dealStatus={dealStatus as DealStatusEnum} />
        {dealStatus === DealStatusEnum.LEAD_VERIFIED && (
          <>
            {leadMeetings.length > 0 ? (
              <>
                <h3 className={styles['sub-title']}>{t('Deal Information')}</h3>
                <div className={styles['details']}>
                  <div className={styles['detail']}>
                    <p>{t('Deal Status')}</p>
                    <h3>
                      {' '}
                      <img
                        src={orangeBulletIcon}
                        alt=""
                        role="presentation"
                      />{' '}
                      {t('Pending Client Decision')}
                    </h3>
                  </div>
                </div>
                <hr />
              </>
            ) : null}

            <div className={styles['flex-between']}>
              <h3 className={styles['sub-title']}>{t('scheduledMeetings')}</h3>
              <Link
                to={ROUTES['NewMeeting'].getPath?.(
                  dealId,
                  countryId,
                  duplicate,
                  brokerId
                )}
                className="btn btn--navy"
              >
                {t('newMeeting')}
              </Link>
            </div>
            {dealId &&
              countryId &&
              dealStatus === DealStatusEnum.LEAD_VERIFIED && (
                <LeadScheduledMeetings
                  dealId={dealId}
                  countryId={countryId}
                  leadMeetings={leadMeetings}
                  setLeadMeetings={setLeadMeetings}
                />
              )}

            <hr />
          </>
        )}
        {submitInvoice && selectedSale?.source_id && dealId && countryId && (
          <SubmitInvoice
            dealId={dealId}
            countryId={countryId}
            setLoading={setLoading}
            setSubmittingInvoice={setSubmittingInvoice}
            saleId={selectedSale?.source_id}
            contractualDate={selectedSale?.contractual_date?.toString()}
          />
        )}

        {(dealStatus === DealStatusEnum.SUBMITTED_INVOICE ||
          dealStatus === DealStatusEnum.INVOICE_APPROVED) &&
          dealId && (
            <DealDetailsInvoice
              loading={loading}
              salesInvoices={salesInvoices}
            />
          )}
        {dealStatus === DealStatusEnum.LEAD_RESERVED &&
        leadInfo?.sales?.length ? (
          <>
            <h3 className={styles['sub-title']}>{t('Reservation Status')}</h3>
            <div className={styles['details']}>
              <div className={styles['detail']}>
                <p>{t('Sale Status')}</p>

                <h3>
                  {reservedSaleStatus === SaleStatusEnum.RESERVED ? (
                    <>
                      <img src={greenBulletIcon} alt="" role="presentation" />{' '}
                    </>
                  ) : (
                    <>
                      <img src={orangeBulletIcon} alt="" role="presentation" />{' '}
                    </>
                  )}
                  {reservedSaleStatus?.replace(/_/g, ' ')}
                </h3>
              </div>
              {selectedSale?.remaining_reservation_amount ? (
                <div className={styles['detail']}>
                  <p>{t('Remaining Reservation Amount')}</p>
                  <h3>
                    {Numeral(selectedSale?.remaining_reservation_amount).format(
                      '0,0'
                    )}{' '}
                    {selectedSale?.unit.currency}
                  </h3>
                </div>
              ) : null}
              {selectedSale?.remaining_due_date ? (
                <div className={styles['detail']}>
                  <p>{t('Remaining Reservation Due Date')}</p>
                  <h3>
                    {format(
                      new Date(selectedSale?.remaining_due_date * 1000),
                      'dd MMMM yyyy'
                    )}
                  </h3>
                </div>
              ) : null}
              {reservedSaleStatus === SaleStatusEnum.RESERVED ? (
                <div className={styles['detail']}>
                  <p>{t('Contract Signing Date')}</p>
                  <h3>
                    {selectedSale?.contract_sign_date
                      ? format(
                          new Date(selectedSale?.contract_sign_date * 1000),
                          'dd MMMM yyyy'
                        )
                      : null}
                  </h3>
                </div>
              ) : null}
            </div>
            <hr />

            <h3 className={styles['sub-title']}>{t('scheduledMeetings')}</h3>
            {dealId && countryId && (
              <LeadScheduledMeetings
                dealId={dealId}
                countryId={countryId}
                leadMeetings={leadMeetings}
                setLeadMeetings={setLeadMeetings}
              />
            )}
            <hr />
          </>
        ) : null}
        <LeadInfoCard
          leadInfo={leadInfo}
          dealStatus={dealStatus as DealStatusEnum}
        />
        {dealUnit && dealStatus && leadInfo && (
          <DealDetailsUnitCard
            leadName={leadInfo.name}
            unitDetails={dealUnit}
            dealStatus={dealStatus}
          />
        )}
        {dealStatus === DealStatusEnum.LEAD_SUBMITTED && (
          <>
            <hr />
            <h3 className={styles['sub-title']}>{t('scheduledMeetings')}</h3>
            <p className={styles['note--secondary']}>
              {t('leadIsUnderReviewByTeam')}
            </p>
          </>
        )}
        {(dealStatus === DealStatusEnum.DEAL_CLOSED ||
          dealStatus === DealStatusEnum.SUBMITTED_INVOICE ||
          dealStatus === DealStatusEnum.INVOICE_APPROVED) && (
          <>
            <hr />
            <h3 className={styles['sub-title']}>{t('scheduledMeetings')}</h3>
            {dealId && countryId && (
              <LeadScheduledMeetings
                dealId={dealId}
                countryId={countryId}
                leadMeetings={leadMeetings}
                setLeadMeetings={setLeadMeetings}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default DealDetails;
