import { useTranslation } from 'react-i18next';
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  Title,
  Legend,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import styles from './units-sold-per-destination.module.scss';
import { useContext, useEffect, useState } from 'react';
import { FilterValue, UserRole } from '@orascom/api-interfaces';
import { Loader } from '@orascom/common-components';
import { Analytics as AnalyticsUtils } from '../../utils/analytics';
import { SalesCountPerDestination } from '../../definitions/interfaces/analytics';
import {
  AnalyticsFilter,
  useAnalyticsFilters,
  USER_CONTEXT,
} from '@orascom/broker-sales-man-common-components';
import { AnalyticsSalesManager as AnalyticsSalesManagerApi } from '../../utils/analytics-sales-manager';
import queryString from 'query-string';
import { getButtonText } from '@orascom/utils';
// Register the plugins to the chart
Chart.register(
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  Title,
  Legend,
  Tooltip
);

interface UnitsSoldPerDestinationProps {
  filterUnitTypes: FilterValue[];
  filterBrokerAgents?: FilterValue[];
  filterBrokerages?: FilterValue[];
}
export function UnitsSoldPerDestination(
  props: Readonly<UnitsSoldPerDestinationProps>
) {
  const { t } = useTranslation();
  const userContext = useContext(USER_CONTEXT);
  const [salesCountPerDestination, setSalesCountPerDestination] = useState<
    SalesCountPerDestination[]
  >([]);

  const [loading, setLoading] = useState(true);

  const filtersParams = queryString.parse(window.location.search);

  const {
    selectedUnitTypes,
    handleSelectedUnitTypes,
    selectedBrokerAgents,
    handleSelectedBrokerAgents,
    selectedBrokerage,
    handleSelectedBrokerage,
    selectedUnitTypesIds,
    selectedBrokerAgentsIds,
    selectedBrokeragesIds,
    startDateArg,
    endDateArg,
  } = useAnalyticsFilters();

  const barGraphLabel = getButtonText(
    filtersParams['durationType']?.toString()
  );

  useEffect(() => {
    let promise;
    if (userContext.user?.role === UserRole.SALES_MANAGER) {
      promise =
        AnalyticsSalesManagerApi.getAnalyticsSalesCountPerDestinationSalesManager(
          {
            from: startDateArg,
            to: endDateArg,
            'unit_type_ids[]': selectedUnitTypesIds,
            'brokerage_company_ids[]': selectedBrokeragesIds,
          }
        );
    } else {
      promise = AnalyticsUtils.getAnalyticsSalesCountPerDestination({
        from: startDateArg,
        to: endDateArg,
        'unit_type_ids[]': selectedUnitTypesIds,
        'broker_ids[]': selectedBrokerAgentsIds,
      });
    }
    promise
      .then((res) => {
        setSalesCountPerDestination(res);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  }, [
    filtersParams['start'],
    filtersParams['end'],
    selectedUnitTypes,
    selectedBrokerAgents,
    selectedBrokerage,
  ]);

  if (loading) {
    return <Loader />;
  }
  if (!salesCountPerDestination) {
    return <h1>No Sales Found Per Destination</h1>;
  }
  return (
    <div className={`${styles['wrapper']} broker-analytic-wrapper`}>
      <h3 className="title">
        {t('numberOfunitsSoldPerDestination')}
        {userContext.user?.role === UserRole.BROKER ? (
          <AnalyticsFilter
            countriesFilter
            filterUnitTypes={props.filterUnitTypes}
            handleSelectedUnitTypes={handleSelectedUnitTypes}
            filterBrokerAgents={props.filterBrokerAgents}
            handleSelectBrokerAgents={handleSelectedBrokerAgents}
          />
        ) : null}
      </h3>
      <div className={`card ${styles['chart-wrapper']}`}>
        {userContext.user?.role === UserRole.SALES_MANAGER ? (
          <div className={styles['card-filters']}>
            <AnalyticsFilter
              unitSizeFilter
              buyerCountry
              filterBrokerages={props.filterBrokerages}
              filterUnitTypes={props.filterUnitTypes}
              handleSelectedUnitTypes={handleSelectedUnitTypes}
              filterBrokerAgents={props.filterBrokerAgents}
              handleSelectBrokerages={handleSelectedBrokerage}
            />
          </div>
        ) : null}
        <div className={styles['chart']}>
          <Bar
            id="bar-chart"
            data={{
              labels: salesCountPerDestination.map((d) => d.name),
              datasets: [
                {
                  label: filtersParams['start'] ? barGraphLabel : t('lastYear'),
                  data: salesCountPerDestination.map((d) => d.sales_count),
                  backgroundColor: '#003A70',
                  hoverBackgroundColor: '#4C759B',
                  borderRadius: 8,
                  borderSkipped: false,
                  maxBarThickness: 30,
                  categoryPercentage: 0.5,
                  barPercentage: 1,
                },
              ],
            }}
            options={{
              responsive: true,
              maintainAspectRatio: window.innerWidth > 480,
              plugins: {
                legend: {
                  position: 'bottom' as const,
                  align: 'start' as const,
                  labels: {
                    boxWidth: 18,
                    boxHeight: 18,
                    borderRadius: 9,
                    useBorderRadius: true,
                    padding: 30,
                  },
                },
              },
              scales: {
                x: {
                  grid: {
                    display: false,
                  },
                },
                y: {
                  grid: {
                    color: 'rgba(21, 44, 91, 0.1)',
                    lineWidth: 0.5,
                  },
                },
              },
              onHover: (event, chartElement) => {
                (event.native?.target as HTMLDivElement).style.cursor =
                  chartElement[0] ? 'pointer' : 'default';
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default UnitsSoldPerDestination;
