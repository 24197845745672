import { Payload, UnitInterface } from '@orascom/api-interfaces';
import styles from './units-listing.module.scss';
import SelectIcon from '../../assets/icons/left-arrow.svg?react';
import PropertyCard from '../property-card/property-card';

export interface UnitsListingProps {
  portal: 'broker' | 'sales-man';
  id: string;
  units: UnitInterface[];
  handleRemove?: (id: number) => void;
  listingView?: 'list' | 'grid';
  unitDetailsPath?: Function;
  addSavedUnit: (unitId: number) => Promise<Payload<void>>;
  deleteSavedUnit: (unitId: number) => Promise<Payload<void>>;
}

export function UnitsListing(props: Readonly<UnitsListingProps>) {
  const { units } = props;

  return (
    <div className={styles['wrapper']}>
      <div
        className={`${styles['cards']} ${
          props.listingView &&
          props.listingView === 'list' &&
          styles['cards--list-view']
        }`}
        id={props.id}
      >
        {units.map((unit) => (
          <div className={styles['property-card-wrapper']} key={unit.id}>
            {props.listingView && props.listingView === 'list' && (
              <div className={styles['select-svg']}>
                <div>
                  <SelectIcon />
                </div>
              </div>
            )}
            <PropertyCard
              unit={unit}
              portal={props.portal}
              handleRemove={props.handleRemove ?? undefined}
              listingView={props.listingView}
              unitDetailsPath={props.unitDetailsPath}
              addSavedUnit={props.addSavedUnit}
              deleteSavedUnit={props.deleteSavedUnit}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default UnitsListing;
