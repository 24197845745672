import updatesIcon from '../../assets/icons/updates.svg';
import { notificationTimeCalculator } from '@orascom/utils';
import styles from './notifications-wrapper.module.scss';
import { UpdatesInterface } from '@orascom/api-interfaces';
import { ROUTES } from '../../api/routes';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { USER_CONTEXT } from '@orascom/broker-sales-man-common-components';
import { Updates as UpdatesUtils } from '../../utils/updates';
import arrowIcon from '../../assets/icons/left-arrow.svg';
import { useTranslation } from 'react-i18next';

export interface NotificationsUpdatesProps {
  notification: UpdatesInterface;
  dashboard?: boolean;
}
export function NotificationsUpdates(
  props: Readonly<NotificationsUpdatesProps>
) {
  const { t } = useTranslation();
  const userContext = useContext(USER_CONTEXT);
  return (
    <div
      key={props.notification.id}
      className={`card ${props.dashboard ? styles['news'] : styles['update']}`}
    >
      <div className={styles['info']}>
        <div className={styles['icon']}>
          <img src={updatesIcon} alt="" role="presentation" />
        </div>
        <p className={styles['content']}>{props.notification.message}</p>
      </div>
      {props.notification.redirect_data.lead_id ? (
        <Link
          to={ROUTES['DealDetails'].getPath?.(
            props.notification.redirect_data.lead_id,
            props.notification.redirect_data.country_id,
            '0',
            userContext.user?.id
          )}
          state={{ saleId: props.notification.redirect_data.sale_id }}
          className={styles['more-details']}
          onClick={() => {
            UpdatesUtils.readNotifications([props.notification.id])
              .then()
              .catch((err) => console.error(err));
          }}
        >
          {t('moreDetails')}
          <img
            className={styles['arrow-right']}
            src={arrowIcon}
            alt=""
            role="presentation"
          />
        </Link>
      ) : null}
      <div className={styles['time']}>
        {notificationTimeCalculator(props.notification.created_at)}
      </div>
    </div>
  );
}

export default NotificationsUpdates;
