import { UnitDetails, UserInterface } from '@orascom/api-interfaces';
import { COUNTRIES } from '../consts/deals-status';

export enum DealStatusEnum {
  LEAD_SUBMITTED = 'lead_submitted',
  LEAD_VERIFIED = 'lead_verified',
  DEAL_CLOSED = 'deal_closed',
  SUBMITTED_INVOICE = 'submitted_invoice',
  INVOICE_APPROVED = 'invoice_approved',
  PAST_DEALS = 'past_deals',
  LEAD_RESERVED = 'lead_reserved',
}

export enum SaleStatusEnum {
  PENDING_ODH_REVIEW = 'pending_odh_review',
  PARTIALLY_RESERVED = 'partially_reserved',
  RESERVED = 'reserved',
  CLOSED = 'closed',
  LOST = 'lost',
}

export enum SalesInvoiceStatusEnum {
  PENDING = 'pending',
  RECEIVED = 'received',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export interface DealsResponse {
  active_deals: DealInterface[];
  past_deals: DealInterface[];
}

export interface SaleInterface {
  source_id: string;
  name: string;
  contracting_status: string;
  reserved_sale_status: SaleStatusEnum;
  deal_status: DealStatusEnum | null;
  remaining_reservation_amount: number;
  remaining_due_date: number;
  collect_reservation: string;
  contract_sign_date: number;
  contractual_date: number;
  unit: UnitDetails;
  sales_invoices?: SalesInvoiceInterface[];
}
export interface DealInterface {
  lead_source_id: string;
  lead_history_source_id?: string;
  interested_in_unit_details?: UnitDetails;
  email: string;
  name: string;
  mobile: string;
  phone?: string;
  phone_2?: string;
  phone_3?: string;
  status_code: string;
  lead_submitted_at?: number;
  sales?: SaleInterface[];
  country_slug: COUNTRIES;
  country_id: number;
  sales_admin_verification_decision?: string;
  broker: UserInterface;
  lead_status: DealStatusEnum | null;
  lead?: {
    email: string;
    interested_in_unit_source_id: string;
    lead_source_id: string;
    phone: string;
    status_code: string;
  };
}

export interface SalesInvoiceInterface {
  submission_date: number;
  expected_payout_date: number;
  amount: number;
  status: SalesInvoiceStatusEnum;
  commission_payment_status: boolean;
  rejection_reason: string;
  currency: string;
}

export const statusesOptions = [
  {
    label: 'lead submitted',
    value: 'lead submitted',
  },
  {
    label: 'lead verified',
    value: 'lead verified',
  },
  {
    label: 'deal closed',
    value: 'deal closed',
  },
  {
    label: 'submitted invoice',
    value: 'submitted invoice',
  },
  {
    label: 'invoice approved',
    value: 'invoice approved',
  },
  {
    label: 'lead reserved',
    value: 'lead reserved',
  },
];
