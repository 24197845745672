/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react';
import styles from './image-gallery.module.scss';
import { v4 as uuidv4 } from 'uuid';
import RightArrow from '../../assets/icons/right-arrow.svg';

export interface ImageGalleryProps {
  images: string[];
}

export function ImageGallery(props: Readonly<ImageGalleryProps>) {
  const [images, setImages] = useState<string[]>([]);

  useEffect(() => {
    setImages(props.images);
  }, [props.images]);

  /**
   * Moves the selected image to the first position to "enlarge" it via CSS.
   *
   * @param {number} index
   */
  const enlargeImgHandler = (index: number) => {
    if (index === 0) return;
    const reorderedImages = [...images];
    const [selectedImage] = reorderedImages.splice(index, 1); // Remove the clicked image
    reorderedImages.unshift(selectedImage); // Add it to the start
    setImages(reorderedImages);
  };

  /**
   * Navigates to the next image by reordering images array.
   */
  const handleNext = () => {
    const reorderedImages = [...images];
    reorderedImages.push(reorderedImages.shift()!); // Move the first image to the end
    setImages(reorderedImages);
  };

  /**
   * Navigates to the previous image by reordering images array.
   */
  const handlePrev = () => {
    const reorderedImages = [...images];
    reorderedImages.unshift(reorderedImages.pop()!); // Move the last image to the start
    setImages(reorderedImages);
  };

  return (
    <div className={styles['wrapper']}>
      <button
        onClick={handlePrev}
        className={`${styles['btn-wrapper--left']} ${styles['btn-wrapper']}`}
      >
        <img src={RightArrow} alt="Previous" />
      </button>

      <div className={styles['images-container']}>
        {images.map((img, index) => (
          <img
            key={uuidv4()}
            src={img}
            alt={`Image ${index}`}
            className={styles['image']}
            onClick={() => enlargeImgHandler(index)}
            onKeyDown={() => enlargeImgHandler(index)}
            loading="lazy"
          />
        ))}
      </div>

      <button
        onClick={handleNext}
        className={`${styles['btn-wrapper--right']} ${styles['btn-wrapper']}`}
      >
        <img src={RightArrow} alt="Next" />
      </button>
    </div>
  );
}

export default ImageGallery;
