/**
 * @author Salma Hefnawy
 * @date 2022-10-07
 * @description implementation of deals related utility functions.
 * @filename deal.ts
 */

import { Common, Payload } from '@orascom/api-interfaces';
import { t } from 'i18next';
import { Deal as DealAPI } from '../api/deal';
import {
  MeetingInterface,
  MeetingStatusEnum,
  NewDealFormInterface,
  NewMeetingInterface,
  ShareReservationLinlInterface,
} from '../definitions/interfaces/inputs/new-deal';
import {
  DealInterface,
  DealsResponse,
  SalesInvoiceInterface,
} from '../definitions/interfaces/deals.interface';

/**
 * group of Deal helpers functionalities.
 */
export class Deal {
  public static createLead(
    leadData: NewDealFormInterface
  ): Promise<Payload<void>> {
    return DealAPI.createLead(leadData)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static createMeeting(
    dealId: string,
    countryId: string,
    meetingData: NewMeetingInterface
  ): Promise<Payload<void>> {
    return DealAPI.createMeeting(dealId, countryId, meetingData)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static getLeads(): Promise<Payload<DealsResponse>> {
    return DealAPI.getLeads()
      .then((result) => {
        return Promise.resolve(result.data);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static getDuplicateLeads(): Promise<Payload<DealsResponse>> {
    return DealAPI.getDuplicateLeads()
      .then((result) => {
        return Promise.resolve(result.data);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static getLeadById(
    dealId: string,
    countryId: string,
    duplicate: '1' | '0',
    brokerId: string
  ): Promise<Payload<DealInterface>> {
    return DealAPI.getLeadById(dealId, countryId, duplicate, brokerId)
      .then((result) => {
        return Promise.resolve(result.data);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static getSaleInvoice(
    dealId: string
  ): Promise<Payload<SalesInvoiceInterface[]>> {
    return DealAPI.getSaleInvoice(dealId)
      .then((result) => {
        return Promise.resolve(result.data);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static getMeetings(): Promise<Payload<MeetingInterface[]>> {
    return DealAPI.getMeetings()
      .then((result) => {
        return Promise.resolve(result.data);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static getLeadMeetings(
    dealId: string,
    countryId: string
  ): Promise<Payload<MeetingInterface[]>> {
    return DealAPI.getLeadMeetings(dealId, countryId)
      .then((result) => {
        return Promise.resolve(result.data);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static submitLeadInvoice(
    dealId: string,
    countryId: string,
    formData: FormData
  ): Promise<Payload<Common>> {
    return DealAPI.submitLeadInvoice(dealId, countryId, formData)
      .then((result): Promise<Payload<Common>> => {
        return Promise.resolve(result);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static shareReservationLink(
    unitId: string,
    reservationLinkData: ShareReservationLinlInterface
  ): Promise<Payload<void>> {
    return DealAPI.shareReservationLink(unitId, reservationLinkData)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }
}

export const groupLeadsByBroker = (
  array: DealInterface[]
): DealInterface[][] => {
  // Return the end result
  const result = array.reduce(function (
    accumulator: any,
    currentValue: DealInterface
  ) {
    if (currentValue.broker) {
      accumulator[currentValue.broker.id] =
        accumulator[currentValue.broker.id] || [];
      accumulator[currentValue.broker.id].push(currentValue);
      return accumulator;
    } else {
      return [];
    }
  },
  Object.create(null));

  return Object.values(result);
};

export const meetingStatusData = (meetingStatus: MeetingStatusEnum | null) => {
  switch (meetingStatus?.toString()) {
    case MeetingStatusEnum.APPROVED:
      return {
        statusText: t('confirmed'),
        statusClassName: 'status--confirmed',
        statusRingClassName: 'status-ring--confirmed',
      };

    case MeetingStatusEnum.REJECTED:
      return {
        statusText: t('rejected'),
        statusClassName: 'status--rejected',
        statusRingClassName: 'status-ring--rejected',
      };

    default:
      return {
        statusText: t('pending'),
        statusClassName: 'status--pending',
        statusRingClassName: 'status-ring--pending',
      };
  }
};
